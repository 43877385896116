<template>
  <div ref="screen">
    <v-snackbar
      :timeout="snackbar.timeout"
      v-model="snackbar.state"
      bottom
      right
      style="padding: 0 18% 1% 0 !important"
      :color="snackbar.color"
      text
    >
      <v-icon left color="#8C7913">{{ snackbar.icon }}</v-icon>
      <span style="color: #8C7913">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#8C7913"
          text
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-if="!show_direction"
      v-model="speaking"
      :timeout="1000"
      style="z-index: 999999;position: fixed !important;padding: 7% 5% 0 0 !important"
      absolute
      top
      color="error"
      class="text-center speech-detection"
      text
      outlined
      right
    >
      <div
        class="subtitle-2 font-weight-bold d-flex justify-center align-center"
      >
        Speech Detected
      </div>
    </v-snackbar>
    <v-row id="soal">
      <template v-if="loading.state">
        <v-col cols="12" md="8" class="ma-auto">
          <v-spacer class="py-16"></v-spacer>
          <p class="fs-24 primary--text font-weight-bold text-center">
            Preparing Reading Section
          </p>
          <v-spacer class="py-5"></v-spacer>
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div class="text-center color--text text--lighten-3">
            {{ loading.text }}
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" lg="3" md="3" class="pa-1" style="position: fixed">
          <v-card
            rounded="lg"
            elevation="0"
            class="ma-auto px-4 py-2 overflow-auto mx-width"
            style="max-height: calc(100vh - 70px)"
          >
            <div class="box-timer pa-2 py-0">
              <p class="font-weight-medium color-state fs-18 mb-0">
                Waktu tersisa
              </p>
              <p class="primary--text font-weight-bold fs-20 mb-0">
                {{ totalSeconds | minutesAndSeconds }}
              </p>
            </div>
            <v-divider></v-divider>

            <!-- Pelanggaran -->
            <div
              class="pa-2 mb-3"
              v-show="showIconTab || showIconFace || showIconSpeech"
            >
              <div class="font-weight-medium color-state fs-18">
                Pelanggaran
              </div>
              <v-row align="center" class="mt-2">
                <v-col
                  v-show="showIconTab"
                  cols="4"
                  md="4"
                  sm="4"
                  class="text-center pa-0"
                >
                  <div class="pl-0 mb-2" style="font-size: .6rem !important">
                    Tab Baru
                  </div>
                  <v-badge
                    :content="lms_open_tab()"
                    bottom
                    color="#EF2F39"
                    overlap
                  >
                    <v-avatar tile size="25">
                      <v-img
                        :src="require('@/assets/images/open_new_tab.png')"
                      ></v-img>
                    </v-avatar>
                  </v-badge>
                </v-col>
                <v-col
                  v-show="showIconFace"
                  cols="4"
                  md="4"
                  sm="4"
                  class="text-center pa-0"
                >
                  <div class="pl-0 mb-2" style="font-size: .6rem !important">
                    Deteksi Wajah
                  </div>
                  <v-badge
                    :content="lms_face_detection()"
                    bottom
                    color="#EF2F39"
                    overlap
                  >
                    <v-avatar tile size="25">
                      <v-img
                        :src="require('@/assets/images/face_detection.png')"
                      ></v-img>
                    </v-avatar>
                  </v-badge>
                </v-col>
                <v-col
                  v-show="showIconSpeech"
                  cols="4"
                  md="4"
                  sm="4"
                  class="text-center pa-0"
                >
                  <div class="pl-0 mb-2" style="font-size: .6rem !important">
                    Speech Voice
                  </div>
                  <v-badge
                    :content="lms_speech_detection()"
                    bottom
                    color="#EF2F39"
                    overlap
                  >
                    <v-avatar tile size="25">
                      <v-img
                        :src="require('@/assets/images/speech_detection.png')"
                      ></v-img>
                    </v-avatar>
                  </v-badge>
                </v-col>
              </v-row>
            </div>
            <!-- <div v-else class="pa-2 mb-3">
              <div class="font-weight-medium color-state fs-18">
                Pelanggaran
              </div>
              <v-row justify="center" align="center" class="mt-2">
                <v-col cols="4" md="4" sm="4" class="text-center pa-0">
                  <div class="pl-0 mb-0" style="font-size: .6rem !important">
                    Tab Baru
                  </div>
                  <v-progress-circular
                    :rotate="-90"
                    :size="40"
                    :width="4"
                    :value="board.counter_tab"
                    color="#652065">
                    {{ lms_open_tab() }}
                  </v-progress-circular>
                </v-col>
                <v-col cols="4" md="4" sm="4" class="text-center pa-0">
                  <div class="pl-0 mb-0" style="font-size: .6rem !important">
                    Deteksi Wajah
                  </div>
                  <v-progress-circular
                    :rotate="-90"
                    :size="40"
                    :width="4"
                    :value="board.counter_face"
                    color="#652065">
                    {{ lms_face_detection() }}
                  </v-progress-circular>
                </v-col>
                <v-col cols="4" md="4" sm="4" class="text-center pa-0">
                  <div class="pl-0 mb-0" style="font-size: .6rem !important">
                    Speech Voice
                  </div>
                  <v-progress-circular
                    :rotate="-90"
                    :size="40"
                    :width="4"
                    :value="board.counter_speech"
                    color="#652065">
                    {{ lms_speech_detection() }}
                  </v-progress-circular>
                </v-col>
              </v-row>
            </div> -->
            <v-divider></v-divider>

            <!-- Navigasi -->
            <div class="navigasi pa-2" style="max-width: 305px">
              <p class="font-weight-medium color-state fs-18 mb-0">
                Navigasi soal
              </p>
              <div class="btn-question">
                <v-row>
                  <v-col id="tes" class="py-0">
                    <v-btn
                      v-for="index in questions.length"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="cek-question pa-2 px-0">
              <table>
                <tr style="color: #61ac33">
                  <td>Soal Terjawab</td>
                  <td></td>
                  <td>:</td>
                  <td class="ms-2 font-weight-medium">
                    {{ countAnswer.length }}
                  </td>
                </tr>
                <tr style="color: #cd223a">
                  <td>Belum Terjawab</td>
                  <td></td>
                  <td>:</td>
                  <td class="ms-2 font-weight-medium">
                    {{ questions.length - countAnswer.length }}
                  </td>
                </tr>
              </table>
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="9"
          md="9"
          class="pl-2 pa-1"
          style="position: absolute; right: 0"
        >
          <v-card rounded="lg" elevation="0" class="ma-auto px-5 py-2">
            <div class="title">
              <v-row>
                <v-col cols="12" md="8" style="align-self: center">
                  <p class="fs-24 font-weight-bold color-state">
                    Reading Comprehension Section
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <div v-if="!show_direction">
                    <div
                      v-if="speaking"
                      class="mt-2 d-flex justify-center align-center float-right"
                      style="
                        width: 150px;
                        height: 40px;
                        border-radius: 5px;
                        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2) !important;
                        border: 2px solid #000">
                      <div
                        class="subtitle-2 font-weight-bold">
                        Speech Detected
                      </div>
                    </div>
                  </div> -->
                  <v-btn
                    color="primary"
                    class="float-right mt-2 me-2"
                    v-on:click="nextR"
                    v-if="show_direction"
                    >Start Test</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <div class="box-section pb-5">
              <!-- <div class="greeting" v-show="greetingReading"> -->
              <div class="greeting" v-if="show_direction">
                <div class="instruction pb-10">
                  <p class="fs-14 color-disable" style="white-space: pre-line">
                    <!-- {{ current_part.part_direction.text }} -->
                    {{ current_part ? current_part.part_direction.text : "" }}
                  </p>
                  <div
                    v-if="this.current_part.part_direction.with_media == true"
                  >
                    <img
                      :src="current_part.part_direction.media.public_url"
                      class="img-question"
                    />
                  </div>
                </div>
              </div>
              <div class="question" v-else-if="current_list">
                <div v-if="this.current_list.is_group == true">
                  <p class="fs-18" style="white-space: pre-line">
                    {{ current_list.text }}
                  </p>
                </div>
                <div
                  v-if="this.current_list.with_media == true"
                  class="text-center"
                >
                  <img
                    :src="current_list.media.public_url"
                    class="img-question"
                  />
                </div>
                <!-- v-for="(question, index) in current_list.list_question" -->
                <!-- :key="question.id" -->
                <epot-question-reading
                  :number="current"
                  :data="current_question"
                  @update:data="
                    $set(current_question, 'selected_answer', $event)
                  "
                />
                <!-- <epot-question-reading
                    v-for="(question, index) in current_list.list_question"
                    :key="question.id"
                    :data="question"
                    :number="current + index"
                    @update:data="$set(current_list.list_question, index, $event)"
                  /> -->
                <!-- <epot-question-reading
                    v-for="(question, index) in current_list.list_question"
                    :key="question.id"
                    :number="current"
                    :data="question"
                    @update:data="$set(current_list.list_question, index, $event)"
                  /> -->
                <v-btn
                  v-if="this.current == 1"
                  @click="goDirection()"
                  color="primary"
                  elevation="0"
                  class="me-2"
                >
                  prev
                </v-btn>
                <v-btn
                  v-if="this.current > 1"
                  v-on:click="prevR"
                  color="primary"
                  elevation="0"
                  class="me-2"
                >
                  prev
                </v-btn>
                <v-btn
                  v-if="this.current != questions.length"
                  v-on:click="nextR"
                  color="primary"
                  elevation="0"
                  class=""
                >
                  next
                </v-btn>
                <v-btn
                  v-if="this.current == questions.length"
                  color="primary"
                  elevation="0"
                  class="mr-3"
                  @click="dialog.confirm = true"
                >
                  Submit
                </v-btn>
              </div>
              <!-- <v-alert
                v-if="this.current == questions.length && images.length < 2"
                class="mt-3"
                text
                color="info"
                small>
                Mohon koreksi kembali jawaban anda
              </v-alert> -->
            </div>
            <v-dialog v-model="dialog.confirm" width="500">
              <v-card>
                <v-card-title class="font-weight-medium fs-24 color-state">
                  Perhatian
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="fs-14 py-4">
                  <span v-if="dialog.takePhoto" class="color-disable">
                    Data Foto anda belum memenuhi, Silahkan klik button Ambil
                    Gambar
                  </span>
                  <span v-else class="color-disable"
                    >Apakah Anda yakin untuk submit jawaban?</span
                  >
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="dialog.takePhoto">
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    outlined
                    :loading="process.run"
                    class="fs-18 pa-4 py-6"
                    @click="TakePicture()"
                  >
                    Ambil Gambar
                  </v-btn>
                </v-card-actions>

                <v-card-actions v-else>
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    outlined
                    class="fs-18 pa-4 py-6"
                    @click="
                      dialog.confirm = false;
                      submiting = false;
                    "
                  >
                    Batalkan
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="fs-18 pa-4 py-6"
                    @click="goFinish()"
                    :loading="submiting"
                  >
                    Yakin
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </template>
      <v-col cols="12" class="pa-0">
        <div class="my-3">
          <video
            v-if="showDetector"
            ref="video"
            class="test-video"
            id="video"
            width="240"
            height="140"
            @loadedmetadata="runModel"
            autoplay
          ></video>
          <video
            v-else
            ref="video"
            class="test-video"
            id="video"
            width="240"
            height="140"
            autoplay
          ></video>
          <canvas
            v-if="showDetector"
            width="240"
            height="140"
            ref="canvas"
            style="z-index: 9999;position: fixed;right: 1%;bottom: 3%;width: 240px;height: 140px;"
          />
          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            width="240"
            height="140"
            class="mr-2"
          ></canvas>
          <canvas
            v-show="isPhotoTaken2"
            id="photoTaken2"
            width="240"
            height="140"
          ></canvas>
          <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
          <div class="camera-shutter" :class="{ flash: isShotPhoto2 }"></div>
        </div>
      </v-col>
      <v-dialog persistent v-model="dialogSubmit.state" width="500">
        <v-card>
          <v-card-title class="font-weight-medium fs-24 color-state">
            Peringatan
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="fs-14 py-4">
            <span class="color-disable">{{ dialogSubmit.message }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="fs-18 pa-4 py-6"
              @click="
                dialogSubmit.status == 'gugur' ? gugur_test() : goFinish()
              "
              :loading="submiting"
            >
              Kirim ulang
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- # DIALOG DETECTION TAB -->
    <v-dialog v-model="dialog.identification_tab" persistent width="600">
      <v-card flat v-if="user.is_b2b == 0">
        <v-card-title style="color: #3C4858" class="font-weight-bold">
          Anda Telah Membuka Tab Baru atau meninggalkan halaman tes
        </v-card-title>
        <v-card-text>
          Anda telah membuka tab baru atau meninggalkan halaman tes , mohon
          untuk tidak membuka tab atau meninggalkan halaman tes lain agar tidak
          mengurangi keakurasian hasil tes.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            @click="plus_counter_tab('open_new_tab')"
            color="primary"
          >
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card flat v-else>
        <v-card-title style="color: #3C4858" class="font-weight-bold">
          Anda Telah Membuka Tab Baru atau meninggalkan halaman tes
        </v-card-title>
        <v-card-text>
          Anda telah membuka tab baru atau meninggalkan halaman tes dan
          terindikasi melakukan kecurangan, mohon untuk tidak membuka tab atau
          meninggalkan halaman tes lain agar tes tidak dihentikan.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            @click="plus_counter_tab('open_new_tab')"
            color="primary"
          >
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- # DIALOG DETECTION SPEECH -->
    <v-dialog v-model="dialog.identification_speech" persistent width="600">
      <v-card flat v-if="user.is_b2b == 0">
        <v-card-title style="color: #3C4858" class="font-weight-bold">
          Percakapan terdeteksi
        </v-card-title>
        <v-card-text>
          Peringatan! Sistem mendeteksi adanya percakapan. Mohon untuk menjaga
          ketenangan saat tes berlangsung, agar tidak mengurangi keakurasian
          hasil tes.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large @click="plus_counter_tab('speech')" color="primary">
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card flat v-else>
        <v-card-title style="color: #3C4858" class="font-weight-bold">
          Percakapan terdeteksi
        </v-card-title>
        <v-card-text>
          Peringatan! Sistem mendeteksi adanya percakapan. Mohon untuk menjaga
          ketenangan saat tes berlangsung, agar tidak mengurangi keakurasian
          hasil tes.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large @click="plus_counter_tab('speech')" color="primary">
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- # DIALOG DETECT MULTI FACE -->
    <v-dialog v-model="dialog.identification_face" persistent width="650">
      <v-card flat v-if="user.is_b2b == 0">
        <v-card-title
          style="color: #3C4858"
          class="font-weight-bold text-center d-flex justify-center align-center"
        >
          Multi-wajah terdeteksi
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>
          Sistem mendeteksi wajah lebih dari satu, pastikan pengerjaan tes
          dilakukan sendiri dan jujur.
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn
            large
            @click="plus_counter_tab('face_detection')"
            color="primary"
          >
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card flat v-else>
        <v-card-title
          style="color: #3C4858"
          class="font-weight-bold text-center d-flex justify-center align-center"
        >
          Multi-wajah terdeteksi
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>
          Sistem mendeteksi wajah lebih dari satu, pastikan pengerjaan tes
          dilakukan sendiri dan jujur.
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn
            large
            @click="plus_counter_tab('face_detection')"
            color="primary"
          >
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- # DIALOG DETECT NO FACE -->
    <v-dialog v-model="dialog.identification_no_face" persistent width="650">
      <v-card flat v-if="user.is_b2b == 0">
        <v-card-title
          style="color: #3C4858"
          class="font-weight-bold d-flex justify-center align-center"
        >
          Wajah tidak terdeteksi
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>
          Sistem tidak mendeteksi wajah anda, pastikan wajah anda terlihat di
          kamera selama tes berlangsung
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn
            large
            @click="plus_counter_tab('face_detection')"
            color="primary"
          >
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card flat v-else>
        <v-card-title
          style="color: #3C4858"
          class="font-weight-bold d-flex justify-center align-center"
        >
          Wajah tidak terdeteksi
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>
          Sistem tidak mendeteksi wajah anda, pastikan wajah anda terlihat di
          kamera selama tes berlangsung
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn
            large
            @click="plus_counter_tab('face_detection')"
            color="primary"
          >
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.end_tes_face" persistent width="600">
      <v-card flat v-if="dialog.end_face_value == 'multi'">
        <v-card-title
          style="color: #3C4858"
          class="font-weight-bold d-flex justify-center align-center"
        >
          Anda terindikasi melakukan kecurangan
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>
          Sistem mendeteksi adanya multi wajah lebih dari 3x, sesuai dengan
          syarat dan ketentuan yang berlaku maka tes anda kami hentikan.
        </v-card-text>
        <!-- <v-card-actions class="d-flex justify-center align-center">
          <v-btn 
            large
            @click="plus_counter_tab('face_detection')" 
            color="primary">
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions> -->
      </v-card>

      <v-card flat v-if="dialog.end_face_value == 'no_face'">
        <v-card-title
          style="color: #3C4858"
          class="font-weight-bold d-flex justify-center align-center"
        >
          Anda terindikasi melakukan kecurangan
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>
          Sistem tidak mendeteksi wajah anda lebih dari 3x, sesuai dengan syarat
          dan ketentuan yang berlaku maka tes anda kami hentikan.
        </v-card-text>
        <!-- <v-card-actions class="d-flex justify-center align-center">
          <v-btn 
            large
            @click="plus_counter_tab('face_detection')" 
            color="primary">
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.end_tes" persistent width="600">
      <v-card flat>
        <v-card-title style="color: #3C4858" class="font-weight-bold">
          Anda terindikasi melakukan kecurangan
        </v-card-title>
        <v-card-text>
          Anda telah membuka tab baru atau meninggalkan halaman tes sebanyak 3x,
          sesuai dengan syarat dan ketentuan yang berlaku maka tes anda kami
          hentikan.
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn 
            large
            :loading="process.run"
            @click="gugur_test()" 
            color="primary">
            Akhiri Tes
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = SpeechRecognition ? new SpeechRecognition() : false;

import EpotQuestionReading from "../../components/EpotQuestionR";
import EpotMixins from "../../mixins/EpotMixins";
import html2canvas from "html2canvas";
import * as faceAPI from "face-api.js";
// import data_section from "@/store/data_section_reading";

export default {
  name: "epot-section-reading",
  mixins: [EpotMixins],
  components: {
    EpotQuestionReading,
  },
  data: function() {
    return {
      // SPEECH DETECTION START
      lang: "id-ID",
      text: {
        type: [String, null],
        required: true,
      },
      error: false,
      speaking: false,
      toggle: false,
      runtimeTranscription: "",
      sentences: [],
      array_text: [],
      // SPEECH DETECTION END

      // ==========================
      dialogSubmit: {},
      submiting: false,
      timer: {},
      totalSeconds: 3300,
      interval: {},
      intervalCapture: null,
      timerDialog: "",
      images: [],
      images_tab: [],
      images_face: [],
      media_speech: [],
      firstImg: "",
      secondImg: "",
      imagePreview: "",

      show_direction: true,
      showDetector: false,
      showIconTab: false,
      showIconFace: false,
      showIconSpeech: false,
      starting_speech: false,

      board: {
        realTimeCountsFace: 0,
        counter_tab: 0,
        counter_face: 0,
        counter_speech: 0,
      },
      dialog: {
        confirm: false,
        takePhoto: false,
        identification_tab: false,
        identification_face: false,
        identification_no_face: false,
        identification_speech: false,
        end_tes: false,
        end_tes_face: false,
        end_face_value: "",
        face_value_condition: "",
      },
      process: {
        run: false,
      },

      snackbar: {
        state: false,
        timeout: 5000,
        text: "Laptop yang anda gunakan untuk test tidak stabil",
        color: "warning",
        icon: "mdi-information",
      },
      isPhotoTaken: false,
      isShotPhoto: false,
      isPhotoTaken2: false,
      isShotPhoto2: false,

      qs: [],
      answered: {
        id: this.$route.params.id,
        section: "reading",
        images: [],
        answer: [
          {
            part: "",
            question: [
              {
                question_id: "",
                answer: [],
              },
            ],
          },
        ],
      },
    };
  },
  filters: {
    minutesAndSeconds(value) {
      var hours = `${Math.floor(value / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(value / 60) % 60}`.padStart(2, 0);
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${hours} : ${minutes} : ${seconds}`;
    },
  },
  watch: {
    // ==================================
    // DIGUNAKAN KETIKA LOADING AWAL BELUM SELESAI TETAPI DETEKSI TAB SUDAH JALAN
    "loading.state": function(val) {
      let vm = this;
      if (val == false) {
        if (vm.user.is_b2b == 1) {
          if (vm.lembaga.setting.identification.new_tab == true) {
            vm.showIconTab = true;
          } else {
            vm.showIconTab = false;
          }
        } else {
          vm.showIconTab = true;
        }
        vm.Detection_tab();
      }
    },

    // ==================================
    // DETECTION CHANGE COUNTER FACE
    "board.realTimeCountsFace": function(face) {
      let vm = this;
      if (face < 1) {
        let faceState = localStorage.getItem("ytc_lms_counter_face");
        if (
          vm.user.is_b2b == 1 &&
          vm.lembaga.setting.failed.face &&
          faceState == 3
        ) {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image();
            vm.dialog.end_face_value = "no_face";
          }
        } else {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image();
          }
        }
      }
      if (face > 1) {
        let faceState = localStorage.getItem("ytc_lms_counter_face");
        if (
          vm.user.is_b2b == 1 &&
          vm.lembaga.setting.failed.face &&
          faceState == 3
        ) {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image();
            vm.dialog.end_face_value = "multi";
          }
        } else {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image();
          }
        }
      }

      if (face == 1) {
        vm.snackbar.state = false;
      }
    },
  },
  async mounted() {
    console.log("starting reading", new Date().toLocaleTimeString());

    // ==================================
    // CAPUTER FACE USER TEST PER 3 MENIT
    let vm = this;
    let randomTimer = Math.floor(Math.random() * (10 - 5 + 1) + 1) * 100000;
    if (vm.user.is_b2b == 1) {
      if (vm.lembaga.active_camera == true) {
        this.intervalCapture = setInterval(() => {
          console.log("This : ", this.images.length);
          console.log("Vm : ", vm.images.length);
          if (vm.images.length <= 4) {
            this.takePhoto();
          } else {
            clearInterval(this.intervalCapture);
          }
        }, randomTimer);
      }
    }

    if (vm.user.is_b2b == 0) {
      this.intervalCapture = setInterval(() => {
        console.log("This : ", this.images.length);
        console.log("Vm : ", vm.images.length);
        if (vm.images.length <= 4) {
          this.takePhoto();
        } else {
          clearInterval(this.intervalCapture);
        }
      }, randomTimer);
    }

    this.lms_open_tab();
    this.lms_face_detection();
    this.lms_speech_detection();

    // await this.checkCompatibility()
    // await this.initModel()

    // Identification Open New Tab
    // let $vm = this;

    // document.addEventListener('visibilitychange', function () {
    //   let counter_tab = localStorage.getItem('ytc_lms_counter_open_tab');

    //   if ($vm.user.is_b2b == 1) {
    //     $vm.axios.get(`/institusion/v1/get/institusion/detail/${$vm.user.institusion.id}`).then((response) => {
    //       let res = response.data;
    //       if (res.status == 200) {
    //         $vm.lembaga = res.data
    //       } else{
    //         console.log("hasil2", response);
    //       }
    //     });
    //     if ($vm.lembaga.setting.identification.new_tab == true) {
    //       if (counter_tab == 3) {
    //         $vm.dialog.end_tes = true;
    //         setTimeout(() => {
    //           $vm.gugur_test()
    //         }, 300)
    //       }else{
    //         $vm.dialog.identification_tab = true;
    //       }
    //     }else{
    //       console.log('identification_tab : false');
    //     }
    //   }else{
    //     $vm.dialog.identification_tab = true;
    //   }
    // })

    window.onbeforeunload = this.prevent_unload;
    this.$route.meta.prevent_move = true;

    this.show_direction = true;
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.onbeforeunload = null;
  },
  created() {
    window.history.forward();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("ytc_lms_auth"));
    },

    lembaga() {
      return JSON.parse(localStorage.getItem("ytc_lms_institution"));
    },

    // ==================================
    // MODEL FACE DETECTION
    initParams() {
      const data = {
        modelUri: "/models",
        option: new faceAPI.SsdMobilenetv1Options({ minConfidence: 0.5 }),
      };
      return data;
    },

    countAnswer() {
      return this.questions.filter((question) => question.selected_answer);
    },

    questions_id() {
      return this.questions.map((question) => {
        let question_id = question.id;
        let answer = [question.selected_answer];
        return {
          question_id,
          answer,
        };
      });
    },

    questions_part() {
      return this.parts.map((arr) => {
        this.answered.answer.part = arr.part;
        let question = arr.questions

          .map((list) => list.list_question)
          .reduce((qs, ans) => [...qs, ...ans], [])
          // .filter((list) => list.selected_answer)
          .map((list) => ({
            question_id: list.id,
            answer: list.selected_answer ? [list.selected_answer] : [null],
          }));

        return {
          part: this.answered.answer.part,
          question,
        };
      });
    },
  },
  methods: {
    // ==================================
    // TAB DETECTION START
    Detection_tab() {
      let $vm = this;
      document.addEventListener("visibilitychange", function() {
        let counter_tab = localStorage.getItem("ytc_lms_counter_open_tab");

        if ($vm.user.is_b2b == 1) {
          // $vm.axios.get(`/institusion/v1/get/institusion/detail/${$vm.user.institusion.id}`).then((response) => {
          //   let res = response.data;
          //   if (res.status == 200) {
          //     $vm.lembaga = res.data
          //   } else{
          //     console.log("hasil2", response);
          //   }
          // });
          if ($vm.lembaga.setting.identification.new_tab == true) {
            $vm.showIconTab = true;
            if (
              counter_tab == 3 &&
              $vm.lembaga.setting.failed.new_tab == true
            ) {
              $vm.dialog.end_tes = true;
              setTimeout(() => {
                $vm.gugur_test();
              }, 300);
            } else {
              $vm.dialog.identification_tab = true;
            }
          } else {
            $vm.showIconTab = false;
            console.log("identification_tab : false");
          }
        } else {
          $vm.showIconTab = true;
          $vm.dialog.identification_tab = true;
        }
      });
    },
    // TAB DETECTION END
    // ==================================

    // ==================================
    // CAMERA STREAM
    async startStream() {
      this.showDetector = true;
      this.showIconFace = true;
      if (this.user.is_b2b == 1) {
        // await this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
        //   let res = response.data;
        //   if (res.status == 200) {
        //     this.lembaga = res.data
        setTimeout(() => {
          if (this.lembaga.active_camera) {
            this.$route.meta.prevent_move = true;
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((mediaStream) => {
                this.$refs.video.srcObject = mediaStream;
                this.$refs.video.play();
                // FACE
                if (this.lembaga.setting.identification.face) {
                  this.showDetector = true;
                  this.showIconFace = true;
                  this.runModel();
                } else {
                  this.showDetector = false;
                  this.showIconFace = false;
                  this.board.realTimeCountsFace = 1;
                }
              })
              .catch((error) => {
                this.showDetector = false;
                this.showIconFace = false;
                this.$refs.video.pause();
                console.error("getUserMedia() error:", error);
              });
          } else {
            this.showDetector = false;
            this.showIconFace = false;
            this.$refs.video.pause();
          }
        }, 2000);
        //   } else{
        //     this.showDetector = false
        //     this.showIconFace = false
        //     console.log("hasil2", response);
        //   }
        // })
      }

      if (this.user.is_b2b == 0) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream) => {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.showDetector = false;
            this.showIconFace = false;
            // this.runModel()
          })
          .catch((error) => {
            this.showDetector = false;
            this.showIconFace = false;
            console.error("getUserMedia() error:", error);
          });
        window.onbeforeunload = function(e) {
          e.preventDefault();
          e.returnValue = "";
        };
        this.$route.meta.prevent_move = true;
      }
    },
    // CAMERA STREAM END
    // ==================================

    // ==================================
    // FACE DETECTION START
    async initModel() {
      // await faceAPI.nets.ssdMobilenetv1.loadFromUri(this.initParams.modelUri)
      // await faceAPI.nets.ageGenderNet.loadFromUri(this.initParams.modelUri)
      this.startStream();
    },

    async runModel() {
      const result = await faceAPI.detectAllFaces(
        this.$refs.video,
        this.initParams.option
      );

      let canvas = this.$refs.canvas;
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      if (result) {
        const dims = faceAPI.matchDimensions(canvas, this.$refs.video, true);
        // console.log('dims : ', dims);
        const resizeResults = faceAPI.resizeResults(result, dims);
        this.board.realTimeCountsFace = resizeResults.length;
        // faceAPI.draw.drawDetections(canvas, resizeResults)
      }
      setTimeout(() => this.runModel());
    },
    // FACE DETECTION END
    // ==================================

    // ==================================
    // SPEECH DETECTION START
    async checkCompatibility() {
      if (!recognition) {
        console.log("no support");
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
      }

      if (this.user.is_b2b == 1) {
        if (this.lembaga.setting.identification.voice == true) {
          this.showIconSpeech = true;
          await this.startSpeechRecognition();
        } else {
          this.showIconSpeech = false;
          console.log(
            "Identification Speech",
            this.lembaga.setting.identification.voice
          );
        }
      }

      if (this.user.is_b2b == 0) {
        this.showIconSpeech = true;
        await this.startSpeechRecognition();
      }
    },
    startSpeechRecognition() {
      if (!recognition) {
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
        return false;
      }

      console.log("start speech");
      this.toggle = true;
      recognition.lang = this.lang;
      recognition.interimResults = true;

      recognition.addEventListener("speechstart", (event) => {
        console.log(event);
        this.speaking = true;
      });

      recognition.addEventListener("speechend", (event) => {
        console.log(event);
        this.speaking = false;
        this.array_text = [];
      });

      recognition.addEventListener("result", (event) => {
        Array.from(event.results)
          .map((result) => result[0])
          .map((result) => this.array_text.push(result.transcript));

        let arr = this.array_text[this.array_text.length - 1];
        let counterSpeech = localStorage.getItem("ytc_lms_counter_speech");

        const text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        this.runtimeTranscription = text;

        if (arr.split(" ").length > 2) {
          // CHECK FOR IS B2B AN GUGUR TEST
          if (this.user.is_b2b == 1) {
            // let data = new Blob([contentfile], {type: 'text/plain'});

            // this.uploadFileTextFile(data)

            if (
              counterSpeech == 3 &&
              this.lembaga.setting.failed.voice == true
            ) {
              console.log("gugur");
              this.dialog.end_tes = true;
              setTimeout(() => {
                this.gugur_test();
              }, 300);
            } else {
              console.log("not gugur");
              console.log(counterSpeech);
              this.dialog.identification_speech = true;
            }
          }

          // CHECK FROM B2C
          if (this.user.is_b2b == 0) {
            this.dialog.identification_speech = true;
          }
        }
      });

      recognition.addEventListener("end", () => {
        let license_code = localStorage.getItem("ytc_lms_license");
        if (this.runtimeTranscription !== "") {
          if (this.runtimeTranscription.split(" ").length > 2) {
            this.count_violation(
              "speech",
              license_code,
              "",
              this.runtimeTranscription
            );
          }
          this.sentences.push(
            this.capitalizeFirstLetter(this.runtimeTranscription)
          );
          this.$emit(
            "update:text",
            `${this.text}${this.sentences.slice(-1)[0]}. `
          );
        }
        this.runtimeTranscription = "";
        recognition.stop();
        if (this.toggle) {
          // keep it going.
          recognition.start();
        }
      });
      recognition.start();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // SPEECH DETECTION END
    // ==================================

    // ==================================
    // COUNTER VIOLATION LOCAL_STORAGE
    lms_open_tab() {
      let counter = localStorage.getItem("ytc_lms_counter_open_tab");
      return counter;
    },

    lms_face_detection() {
      let counter = localStorage.getItem("ytc_lms_counter_face");
      return counter;
    },

    lms_speech_detection() {
      let counter = localStorage.getItem("ytc_lms_counter_speech");
      return counter;
    },
    // ==================================

    // ==================================
    // TAMBAH PERHITUNGAN PELANGGARAN
    async plus_counter_tab(type) {
      if (type == "open_new_tab") {
        this.dialog.identification_tab = false;
        // let tabCounter = localStorage.getItem('ytc_lms_counter_open_tab');
        this.board.counter_tab += 25;
        this.lms_open_tab();
        // let plus = parseInt(tabCounter) + 1
        // localStorage.setItem('ytc_lms_counter_open_tab', plus);
        setTimeout(() => {
          this.capture_image_tab();
        }, 200);
      }

      if (type == "face_detection") {
        this.dialog.identification_face = false;
        this.dialog.identification_no_face = false;
        // let faceCounter = localStorage.getItem('ytc_lms_counter_face')
        this.board.counter_face += 25;
        this.lms_face_detection();
        // let plus = parseInt(faceCounter) + 1
        // localStorage.setItem("ytc_lms_counter_face", plus)
        this.dialog.face_value_condition = "";
      }

      if (type == "speech") {
        this.dialog.identification_speech = false;
        // let license_code = localStorage.getItem('ytc_lms_license');
        // let speechCounter = localStorage.getItem('ytc_lms_counter_speech')
        this.board.counter_speech += 25;
        this.lms_speech_detection();
        // let plus = parseInt(speechCounter) + 1
        // localStorage.setItem('ytc_lms_counter_speech', plus)
        // this.count_violation('voice', license_code, '')
      }
    },
    count_violation(type, license_code, image_id, voice) {
      this.axios
        .put(`/users/v1/member/order/update/violation/${license_code}`, {
          counter_type: type,
          section: "reading",
          image: image_id,
          text_voice: voice,
        })
        .then((res) => {
          const { data } = res.data;
          console.log(data);
          const open_tab = data.section.reading.open_new_tab.count;
          const face = data.section.reading.face.count;
          const speech = data.section.reading.speech.count;
          localStorage.setItem("ytc_lms_counter_open_tab", open_tab);
          localStorage.setItem("ytc_lms_counter_face", face);
          localStorage.setItem("ytc_lms_counter_speech", speech);
        });
    },

    // ==================================
    // GUGUR TEST
    async gugur_test() {
      this.process.run = true;
      this.submiting = true;
      this.timer_stop();
      clearInterval(this.intervalCapture);
      window.onbeforeunload = null;
      this.$route.meta.prevent_move = false;

      // gugur tes Listening
      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            disqualified: true,
            section: "listening",
            images: this.images,
            answer: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.submiting = false;
          this.process.run = false;
        })
        .catch((error) => {
          this.submiting = false;
          this.process.run = false;
          this.dialog.end_tes = false;
          this.dialogSubmit = {
            status: "gugur",
            state: true,
            message: error.response.data.message,
          };
        });

      // gugur tes structure
      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            disqualified: true,
            section: "structure",
            images: this.images,
            answer: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.submiting = false;
          this.process.run = false;
        })
        .catch((error) => {
          this.submiting = false;
          this.process.run = false;
          this.dialog.end_tes = false;
          this.dialogSubmit = {
            status: "gugur",
            state: true,
            message: error.response.data.message,
          };
        });

      // gugur tes reading
      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            disqualified: true,
            section: "reading",
            images: this.images,
            answer: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.submiting = false;
          this.process.run = false;
          localStorage.setItem("ytc_lms_counter_open_tab", 0);
          localStorage.setItem("ytc_lms_counter_face", 0);
          localStorage.setItem("ytc_lms_use_face_detection", false);
          this.$router.push({ path: "/epot/end?type=gugur" });
        })
        .catch((error) => {
          this.submiting = false;
          this.process.run = false;
          this.dialog.end_tes = false;
          this.dialog.end_tes_face = false;
          this.dialog.end_face_value = "";
          this.board.counter_tab = 0;
          this.board.counter_face = 0;
          this.board.counter_speech = 0;
          if (
            error.response.data.message ==
            "Maaf, Tes ini sudah selesai dan terdiskualifikasi"
          ) {
            localStorage.setItem("ytc_lms_counter_open_tab", 0);
            localStorage.setItem("ytc_lms_counter_face", 0);
            localStorage.setItem("ytc_lms_use_face_detection", false);
            this.$router.push({ path: "/epot/end?type=gugur" });
          }
          // this.dialogSubmit = {
          //   status: "gugur",
          //   state: true,
          //   message: error.response.data.message,
          // };
        });
    },

    // ==================================
    // CAPUTER PAGE PELANGGARAN TAB
    async capture_image_tab() {
      let license_code = localStorage.getItem("ytc_lms_license");
      // let el = document.body;
      let el = document.getElementById("app");

      (await html2canvas(el)).toBlob((blob) => {
        let form_data = new FormData();
        form_data.append(
          "title",
          `open-tab-reading-${this.images_tab.length + 1}`
        );
        form_data.append("source", "upload");
        form_data.append(
          "file",
          blob,
          `open-tab-${this.images_tab.length + 1}.jpg`
        );
        this.$store
          .dispatch("media/create", form_data)
          .then((res) => res.data)
          .then((data) => {
            this.count_violation("open_new_tab", license_code, data.id);
            this.images_tab.push(data.id);
          })
          .catch((error) => {
            this.count_violation("open_new_tab", license_code, "");
            console.log(error);
          });
      }, "image/jpeg");
    },

    async TakePicture() {
      this.process.run = true;
      await this.takePhoto();
      await this.takePhoto();
      this.process.run = false;
      this.submiting = false;
      this.dialog.confirm = false;
      this.dialog.takePhoto = false;
    },

    // ==================================
    // TIDAK DIPAKAI, TAPI JANGAN DIHAPUS DULU
    async fetchSection() {
      this.loading.state = true;
      this.loading.text = "Please wait";
      // await setTimeout(async () => {
      await this.axios
        .get(`/users/v1/member/order/detail/${this.$route.params.id}/reading`)
        .then((res) => res.data)
        .then(async (res) => {
          this.section = res.data.exam;
          // console.log('data section', this.section);
          let arr = [];

          // detail exam
          arr = this.parts;
          for (let index = 0; index < arr.length; index++) {
            await this.$store
              .dispatch("exam/detail", arr[index].exam_id)
              .then((res) => res.data)
              .then((data) => {
                this.$set(arr, index, { ...arr[index], ...data });
              })
              .catch((error) => {
                console.error(error);
              });
          }

          arr = this.questions;
          await this.axios
            .get("/bank/v1/question/public_multiple", {
              params: { id: arr.join(",") },
              headers: { "X-Company-ID": this.parts[0].company.id },
            })
            .then((res) => res.data)
            .then((res) => {
              res.data.forEach((val) => {
                this.current = arr.findIndex((v) => v == val.id) + 1;
                const current_list = this.current_list;
                const list_index = current_list.list_question.findIndex(
                  (v) => v == val.id
                );

                this.$set(current_list.list_question, list_index, val);
              });
            })
            .finally(() => {
              this.loading.text = `Almost done`;
            });
        });

      setTimeout(async () => {
        this.current = 1;
        this.loading.state = false;

        await this.checkCompatibility();
        await this.initModel();

        // # IF User B2B
        // if(this.user.institusion.id != ''){
        //   this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
        //     let res = response.data;
        //     if (res.status == 200) {
        //       if (res.data.active_camera) {
        //         this.$route.meta.prevent_move = true;
        //         navigator.mediaDevices
        //         .getUserMedia({ video: true })
        //         .then((mediaStream) => {
        //           console.log(mediaStream);
        //           this.$refs.video.srcObject = mediaStream;
        //           this.$refs.video.play();
        //         }).catch((error) => {
        //           this.$refs.video.pause();
        //           console.error("getUserMedia() error:", error);
        //         });
        //       }else{
        //         this.$refs.video.pause();
        //       }
        //     } else{
        //       console.log("hasil2", response);
        //     }
        //   })

        // }

        // if (this.user.institusion.id == '') {
        //   navigator.mediaDevices
        //     .getUserMedia({ video: true })
        //     .then((mediaStream) => {
        //       this.$refs.video.srcObject = mediaStream;
        //       this.$refs.video.play();
        //     })
        //     .catch((error) => {
        //       console.error("getUserMedia() error:", error);
        //     });
        // }
        this.timer_start();
      }, 1000);
    },

    prevent_unload(e) {
      e.preventDefault();
      e.returnValue = "";
    },

    timer_start() {
      let first = this.totalSeconds - 3;
      // let second = this.totalSeconds - 20;

      this.timer = setInterval(() => {
        this.totalSeconds -= 1;
        if (this.totalSeconds === 0) {
          this.goFinish();
          this.totalSeconds = 0;
          clearInterval(this.timer);
        } else if (this.totalSeconds === first) {
          console.log("oke");
        }
      }, 1000);
    },

    timer_stop() {
      clearInterval(this.interval);
    },

    question_status(index) {
      if (!this.show_direction) {
        if (!index || !this.current) {
          return "bg-accent-default";
        }
        if (index == this.current) {
          return "bg-accent-null";
        }
        if (this.questions[index - 1].selected_answer) {
          return "bg-accent-green";
        }
      }
      return "bg-accent-default";
    },

    goNumber(number) {
      this.current = number;
      // this.question_status(this.current)
    },

    nextR: function() {
      if (!this.starting_speech) {
        this.starting_speech = true;
        if (this.user.is_b2b == 1) {
          if (this.lembaga.setting.identification.voice == true) {
            this.showIconSpeech = true;
            // this.startSpeechRecognition()
          } else {
            this.showIconSpeech = false;
            console.log(
              "Identification Speech",
              this.lembaga.setting.identification.voice
            );
          }
        }

        if (this.user.is_b2b == 0) {
          this.showIconSpeech = true;
          // this.startSpeechRecognition()
        }
      }

      if (this.current == 0) {
        this.show_direction = true;
        this.current++;
      } else if (this.show_direction) {
        this.show_direction = false;
      } else if (this.end_of_part && !this.show_close_statement) {
        this.show_close_statement = true;
      } else if (this.end_of_part && this.show_close_statement) {
        this.current++;
        this.show_close_statement = false;
      } else if (!this.end_of_section) {
        this.current++;
      } else {
        this.current++;
      }
      // this.question_status(this.current)
    },

    prevR: function() {
      if (this.current == 0) {
        this.show_direction = true;
      } else if (this.current == 1) {
        this.show_direction = true;
        this.current--;
      } else if (this.current > 1) {
        this.current--;
      }
      // this.question_status(this.current)
    },

    goDirection() {
      this.show_direction = true;
      // this.questionReading=false;
      // this.greetingReading=true;
      // this.current--;
    },

    // ==================================
    // CAPUTER FACE PELANGGARAN FACE
    async capture_image() {
      let license_code = localStorage.getItem("ytc_lms_license");
      let face_detection_skip =
        localStorage.getItem("ytc_lms_use_face_detection") === "true";
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        let form_data = new FormData();
        form_data.append(
          "title",
          `Reading-violation-${this.images_face.length + 1}`
        );
        form_data.append("source", "upload");
        form_data.append(
          "file",
          blob,
          `Reading-violation-${this.images_face.length + 1}.jpg`
        );
        this.$store
          .dispatch("media/create", form_data)
          .then((res) => res.data)
          .then((data) => {
            this.imagePreview = data.public_url;

            if (
              this.dialog.end_face_value == "multi" ||
              this.dialog.end_face_value == "no_face"
            ) {
              this.dialog.face_value_condition = "end_test";
              this.dialog.end_tes_face = true;
              setTimeout(() => {
                this.gugur_test();
              }, 300);
            } else if (
              this.board.realTimeCountsFace == 0 &&
              this.dialog.end_face_value == ""
            ) {
              this.dialog.face_value_condition = "no_face";
              if (face_detection_skip) {
                this.snackbar.state = true;
                this.snackbar.timeout = -1;
                this.count_violation("face", license_code, data.id);
                this.plus_counter_tab("face_detection");
                this.images_face.push(data.id);
              } else {
                this.dialog.identification_no_face = true;
                this.count_violation("face", license_code, data.id);
                this.images_face.push(data.id);
              }
            } else if (
              this.board.realTimeCountsFace > 1 &&
              this.dialog.end_face_value == ""
            ) {
              this.dialog.face_value_condition = "multi";
              if (face_detection_skip) {
                this.snackbar.state = true;
                this.snackbar.timeout = -1;
                this.count_violation("face", license_code, data.id);
                this.plus_counter_tab("face_detection");
                this.images_face.push(data.id);
              } else {
                this.dialog.identification_face = true;
                this.count_violation("face", license_code, data.id);
                this.images_face.push(data.id);
              }
            }
            // else{
            //   this.images.push(data.id);
            // }
          });
      }, "image/jpeg");
    },

    async takePhoto() {
      // if (!this.isPhotoTaken) {
      //   this.isShotPhoto = false;

      //   const FLASH_TIMEOUT = 50;

      //   setTimeout(() => {
      //     this.isShotPhoto = false;
      //   }, FLASH_TIMEOUT);
      // }

      // this.isPhotoTaken = !this.isPhotoTaken;

      let formData = new FormData();
      let canvas = document.getElementById("photoTaken");

      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, 250, 150);

      let gambar = await fetch(canvas.toDataURL("image/jpeg")).then(function(
        response
      ) {
        return response.blob();
      });

      // window.open(window.URL.createObjectURL(gambar));

      formData.append("file", gambar, `Reading-${this.images.length + 1}.jpg`);
      formData.append("source", "upload");
      formData.append("title", `Reading-${this.images.length + 1}`);
      // console.log('result', formData);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.images.push(res.data.id);
            // this.firstImg = res.data.id;
            // console.log('result', res.data)
          }
          // console.log('result upload', res.message);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async takePhoto2() {
      if (!this.isPhotoTaken2) {
        this.isShotPhoto2 = false;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto2 = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken2 = !this.isPhotoTaken2;

      let formData = new FormData();
      let canvas = document.getElementById("photoTaken2");

      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, 250, 150);

      let gambar = await fetch(canvas.toDataURL("image/jpeg")).then(function(
        response
      ) {
        return response.blob();
      });

      formData.append("file", gambar, "Reading_2.jpg");
      formData.append("source", "upload");
      formData.append("title", "Reading_2");
      // console.log('result', formData);

      await this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          this.secondImg = res.data.id;

          if (res.status == 200) {
            // this.secondImg=res.data.id;
            this.dialog.confirm = true;
            // console.log('result second', res.data)
          }
          // console.log('result upload second', res.message);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async goFinish() {
      clearInterval(this.intervalCapture);
      this.$set(this.dialogSubmit, "message", "Mengirim jawaban.");
      this.submiting = true;

      if (this.images.length < 2) {
        if (this.user.is_b2b == 1) {
          if (this.lembaga.active_camera == true) {
            this.dialog.takePhoto = true;
            return;
            //  await this.takePhoto()
            //  await this.takePhoto()
          }
        }

        if (this.user.is_b2b == 0) {
          this.dialog.takePhoto = true;
          return;
          // await this.takePhoto()
          // await this.takePhoto()
        }
      }

      // if (!this.secondImg) {
      //   await new Promise(resolve => setTimeout(resolve, 2000));
      //   await this.takePhoto2();
      // }
      // this.answered.images = [this.firstImg, this.secondImg];
      this.answered.images = this.images;
      this.answered.answer = this.questions_part;
      await this.axios
        .post(`/users/v1/member/order/answer/`, this.answered, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          localStorage.setItem("ytc_lms_counter_open_tab", 0);
          localStorage.setItem("ytc_lms_counter_face", 0);
          localStorage.setItem("ytc_lms_use_face_detection", false);
          this.board.counter_tab = 0;
          this.board.counter_face = 0;
          this.board.counter_speech = 0;
          window.onbeforeunload = null;
          this.$route.meta.prevent_move = false;
          this.$router.push({ path: "/epot/end" });
        })
        .catch((error) => {
          this.submiting = false;
          console.error(error.response.message);

          if (
            error.response.data.message ==
              "Maaf, section reading sudah selesai" ||
            error.response.data.message == "Maaf, Tes ini sudah selesai"
          ) {
            window.onbeforeunload = null;
            this.$route.meta.prevent_move = false;
            this.$router.push({ path: "/epot/end" });
          } else {
            this.dialogSubmit = {
              status: "kirim_ulang",
              state: true,
              message: error.response.data.message,
            };
          }
        })
        .finally((error) => {
          console.log("errornya", error.response);
          this.submiting = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.speech-detection .v-snack__wrapper {
  min-width: 150px !important;
}
.test-video {
  background-color: #000;
  border-radius: 5px;
  position: fixed;
  right: 1%;
  bottom: 3%;
}
#soal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.box-volume .v-slider--horizontal .v-slider__track-container {
  height: 5px;
}
.alert-instruction .v-alert--text:before {
  background-color: #fefbd8 !important;
}
.alert-instruction .v-alert--text {
  background: #fefbd8 !important;
}
.theme--light
  .box-volume
  .v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
  border-style: hidden;
  border-width: thin 0 0;
}
.box-volume .v-text-field > .v-input__control > .v-input__slot:after {
  border-style: hidden;
}
.img-question {
  max-width: 100%;
  /*max-height: 400px;*/
  object-fit: contain;
}
#photoTaken {
  display: none;
}
#photoTaken2 {
  display: none;
}
@media (max-width: 1024px) {
  #tes {
    text-align: center;
  }
  #tes .my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  #tes .mx-2 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}
</style>
